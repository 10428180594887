import { useFormContext } from "react-hook-form"
import { useEffect, useState, useMemo } from "react"
import { motion } from "framer-motion"
import dynamic from "next/dynamic"
import Button from "@/common/button/button"
import Page from "./Page"
import Storage from "@/lib/storage"
import classNames from "classnames"
import styles from "./Form.module.css"

const allowedFields = [
  "checkbox",
  "consent",
  "email",
  "fileupload",
  "hidden",
  "html",
  "page",
  "phone",
  "quiz",
  "radio",
  "select",
  "text",
  "textarea",
  "tf_image",
  "tf_vote",
  "tf_quiz_result"
]

export default function Form({
  block,
  gformUniqueId,
  form,
  formRef,
  inSidebar,
  isArticle,
  currentPage,
  setCurrentPage,
  uploadFiles,
  setUploadFiles,
  toggleModal
}) {
  const { getValues, setValue, watch } = useFormContext()
  const alwaysRequire = useMemo(() => ["quiz"], [])
  const requiredFields = useMemo(
    () =>
      form.fields.filter(
        (field) =>
          (allowedFields.includes(field.type) && field.isRequired) ||
          alwaysRequire.includes(field.type)
      ),
    [form, alwaysRequire]
  )

  const inputs = watch(requiredFields.map((field) => field.id))
  const collected = inputs.filter((item) => {
    return (
      (typeof item === "string" && item.length > 0) ||
      (Array.isArray(item) && item.filter(Boolean).length > 0)
    )
  }).length
  const [currentPercentage, setCurrentPercentage] = useState(0)

  useEffect(() => {
    const pool =
      requiredFields.length == 0 ? pages.length : requiredFields.length
    const percentage = (collected / pool) * 100
    setCurrentPercentage(percentage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collected, form])

  useEffect(() => {
    if (!form?.useLocalStorageUserInput) {
      return
    }
    const values = getStoredValues(form.id)
    const restoreTypes = ["text", "radio", "select", "email", "phone"]
    form.fields.forEach((field) => {
      if (values[field.id] && restoreTypes.includes(field.type)) {
        setValue(field.id, values[field.id])
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form])

  useEffect(() => {
    if (window.location.search.length === 0) {
      return
    }

    let params = new URLSearchParams(window.location.search)
    form.fields.forEach((field) => {
      if (field.queryString) {
        const value = params.get(field.queryString)
        let strings
        let values = []
        if (value) {
          switch (field.type) {
            case "checkbox":
              strings = value.split(",")
              values = []
              field.choices.forEach((choice) => {
                values.push(
                  strings.includes(choice.value) ? choice.value : false
                )
              })
              setValue(field.id, values)
              break

            default:
              setValue(field.id, value)
              break
          }
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form])

  useEffect(() => {
    if (!form?.useLocalStorageUserInput) {
      return
    }

    if (inputs.filter(Boolean).length === 0) {
      return
    }

    storeValues(form.id, getValues())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs])

  const pages = useMemo(() => {
    const data = []
    if (!form.pagination) {
      data.push({
        page: 1,
        title: "",
        fields: getFields(form, 1)
      })
      return data
    }

    form.pagination.pages.map((page, index) => {
      data.push({
        page: index + 1,
        title: page,
        fields: getFields(form, index + 1)
      })
      return data
    })

    return data
  }, [form])

  const classes = [
    "bg-white text-black-100 rounded",
    { "p-4 md:p-7.5": !inSidebar && !isArticle },
    { "p-4 md:p-7-5": isArticle && block.attrs.parentBlock === "core/group" }
  ]

  return (
    <div className={classNames(classes)}>
      <Header
        form={form}
        currentPage={currentPage}
        currentPercentage={currentPercentage}
      />
      {pages.map((page) => {
        if (page.page !== currentPage) {
          return null
        }

        return (
          <Page
            key={`page-${page.page}`}
            form={form}
            watch={watch}
            currentPage={currentPage}
            fields={page.fields}
            gformUniqueId={gformUniqueId}
            uploadFiles={uploadFiles}
            setUploadFiles={setUploadFiles}
            isArticle={isArticle}
            toggleModal={toggleModal}
          />
        )
      })}
      <Footer
        block={block}
        form={form}
        currentPage={currentPage}
        requiredFields={requiredFields}
        uploadFiles={uploadFiles}
        formRef={formRef}
        setCurrentPage={setCurrentPage}
        pages={pages}
      />
    </div>
  )
}

function Header({ form, currentPage, currentPercentage }) {
  switch (form?.pagination?.type) {
    case "steps":
      return (
        <>
          <span className="block text-sm font-bold mb-3.5 overflow-hidden">
            {form.pagination.pages[currentPage - 1]}
          </span>
          <h3 className="text-2.5xl font-sans mb-5.5">{form.title}</h3>
        </>
      )

    case "percentage":
      return (
        <>
          <div className={styles.progressWrapper}>
            <motion.div
              initial={{ width: 0 }}
              transition={{
                duration: 0.1,
                type: "tween"
              }}
              animate={{ width: currentPercentage + "%" }}
              className={styles.progressBar}
            />
          </div>
          <h3 className="text-2.5xl font-serif mb-4.5">{form.title}</h3>
        </>
      )

    default:
      return <h3 className="text-2.5xl font-serif mb-4.5">{form.title}</h3>
  }
}

function Footer({
  block,
  form,
  currentPage,
  requiredFields,
  formRef,
  uploadFiles,
  setCurrentPage,
  pages
}) {
  const { formState, watch } = useFormContext()
  const buttons = form.pagination
    ? form.pagination.buttons.filter((item) => item.page === currentPage)[0]
    : false
  const [disabledNextStep, setDisabledNextStep] = useState(false)

  useEffect(() => {
    let disabled = false
    const checkFields = requiredFields
      .filter((field) => field.pageNumber === currentPage)
      .map((field) => field.id)

    const inputs = watch(checkFields)
    disabled =
      checkFields.length !==
      inputs.filter((item) => {
        return (
          (typeof item === "string" && item.length > 0) ||
          (Array.isArray(item) && item.filter(Boolean).length > 0)
        )
      }).length

    setDisabledNextStep(disabled)
  }, [currentPage, requiredFields, formState, watch])

  const turnPage = (direction) => {
    let newPage

    if (direction === "prev") {
      newPage = currentPage - 1
    }

    if (direction === "next") {
      newPage = currentPage + 1
    }

    const header = document.getElementById("header")
    const formTop = formRef.current.offsetTop

    window.scrollTo({
      top: formTop - header.offsetHeight - 30,
      behavior: "smooth"
    })

    setCurrentPage(newPage)
  }

  const Previous = () => {
    if (currentPage === 1) {
      return <div></div>
    }

    const buttonText =
      String(buttons?.prevButton?.text).length === 0
        ? "Föregående"
        : buttons?.prevButton?.text

    return (
      <Button
        ariaLabel={buttonText}
        theme="secondary"
        type="button"
        onClick={() => turnPage("prev")}>
        {buttonText}
      </Button>
    )
  }

  const Next = () => {
    if (currentPage === pages.length) {
      return null
    }

    const buttonText =
      String(buttons?.nextButton?.text).length === 0
        ? "Nästa"
        : buttons?.nextButton?.text

    return (
      <Button
        type="button"
        ariaLabel={buttonText}
        onClick={() => turnPage("next")}
        theme={block.attrs?.theme || "green"}
        disabled={disabledNextStep}>
        {buttonText}
      </Button>
    )
  }

  const SubmitButton = ({ uploadFiles }) => {
    if (
      !form.button ||
      pages.length !== currentPage ||
      formState.isSubmitSuccessful
    ) {
      return null
    }

    if (form?.quiz?.hideSubmitButton) {
      return null
    }

    const inputs = watch(requiredFields.map((field) => field.id))

    let validFields = inputs.filter((item) => {
      return (
        (typeof item === "string" && item.length > 0) ||
        (Array.isArray(item) && item.filter(Boolean).length > 0)
      )
    }).length
    requiredFields.forEach((field) => {
      if (
        field.isRequired &&
        field.type === "fileupload" &&
        uploadFiles[field.id] !== undefined &&
        uploadFiles[field.id].length > 0
      ) {
        validFields++
      }
    })

    if (requiredFields.length !== validFields) {
      const classes = [
        "px-5 py-4 mb-0 font-serif text-lg font-bold leading-none text-center transition-colors duration-200 border-2 border-transparent rounded cursor-not-allowed bg-black-5 btn text-black-50",
        {
          "w-full": pages.length === 1 && !form?.quiz?.hasImages,
          "mx-auto": pages.length === 1 && form?.quiz?.hasImages
        }
      ]

      return <div className={classNames(classes)}>{form.button.text}</div>
    }

    const classes = [
      "inline-block px-5 py-4 mb-0 font-serif text-lg font-bold disabled:cursor-not-allowed",
      {
        "opacity-60 cursor-wait": formState.isSubmitting,
        "w-full": pages.length === 1 && !form?.quiz?.hasImages,
        "mx-auto": pages.length === 1 && form?.quiz?.hasImages
      }
    ]

    return (
      <Button
        type="submit"
        ariaLabel={form.button.text}
        loading={formState.isSubmitting}
        theme={block.attrs?.theme || "green"}
        className={classNames(classes)}>
        {form.button.text}
      </Button>
    )
  }

  return (
    <div className="flex justify-between w-full">
      {pages.length > 1 && (
        <>
          <Previous />
          <Next />
        </>
      )}
      <SubmitButton uploadFiles={uploadFiles} />
    </div>
  )
}

function getFields(form, page) {
  return form.fields.filter(
    (field) => field.pageNumber === page && allowedFields.includes(field.type)
  )
}

export function ErrorMessage({ children }) {
  return (
    <span className="inline-block mt-1 text-xs text-red-100">{children}</span>
  )
}

export const fieldTypes = {
  checkbox: dynamic(() => import("./Fields/Checkbox")),
  consent: dynamic(() => import("./Fields/Consent")),
  email: dynamic(() => import("./Fields/Email")),
  fileupload: dynamic(() => import("./Fields/FileUpload")),
  hidden: dynamic(() => import("./Fields/Hidden")),
  html: dynamic(() => import("./Fields/HTML")),
  phone: dynamic(() => import("./Fields/Phone")),
  quiz: dynamic(() => import("./Fields/Quiz")),
  radio: dynamic(() => import("./Fields/Radio")),
  select: dynamic(() => import("./Fields/Select")),
  text: dynamic(() => import("./Fields/Text")),
  textarea: dynamic(() => import("./Fields/Textarea")),
  tf_image: dynamic(() => import("./Fields/Image")),
  tf_vote: dynamic(() => import("./Fields/Vote")),
  tf_quiz_result: dynamic(() => import("./Fields/QuizResult"))
}

export const hideWithConditionalLogic = (field, watch) => {
  const inputs = watch()
  let show = false

  show = field.conditionalLogic.rules.every((rule) => {
    const input = inputs["input_" + rule.fieldId] ?? null
    const inputInt = parseInt(input, 10)
    const ruleValueInt = parseInt(rule.value, 10)

    switch (typeof input) {
      case "string":
        switch (rule.operator) {
          case "is":
            return rule.value === input
          case "isnot":
            return rule.value !== input
          case "contains":
            return input.includes(rule.value)
          case "starts_with":
            return input.startsWith(rule.value)
          case "ends_with":
            return input.endsWith(rule.value)
          case "<":
            return inputInt < ruleValueInt
          case ">":
            return inputInt > ruleValueInt
        }
        break

      case "object":
        if (rule.operator === "is" && Array.isArray(input)) {
          return input.some((value) => rule.value === value)
        }
        if (rule.operator === "isnot" && Array.isArray(input)) {
          return input.every((value) => rule.value !== value)
        }
        break

      default:
        break
    }
  })

  return !show
}

const getStoredValues = (formId) => {
  const userForms = Storage.get("form", [], "session")
  const currentForm = userForms.find((form) => form.id === formId)

  if (currentForm) {
    return currentForm.values
  }

  return []
}

const storeValues = (formId, values) => {
  const userForms = Storage.get("form", [], "session")
  const currentForm = userForms.find((form) => form.id === formId)

  if (currentForm) {
    currentForm.values = values
  } else {
    userForms.push({ id: formId, values })
  }

  Storage.set("form", userForms, "session")
}
